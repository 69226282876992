/* eslint-disable no-lonely-if */
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  useForm,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
} from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';

import { LojaContext } from '~/context/loja';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validations';
import { getPdvs, putConferido, getSearch } from './Services';
import { SearchProps, SelectProps } from './types';
import { putConferidoAll } from './Services/putConferidoAll';
import { parseISO } from 'date-fns';
import moment from 'moment';

interface ConferenciaDeVendasContextData {
  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrorsImpl<{
    [x: string]: any;
  }>;
  getValues: UseFormGetValues<FieldValues>;
  reset: UseFormReset<FieldValues>;
  control: Control<FieldValues, any>;

  handleSearch: () => void;

  watchLoja: number;
  loaderSearch: boolean;
  watchFlgForcarRecalculo: boolean;
  pdvs: SelectProps[];
  handleCancel: () => void;
  searchRows: SearchProps[];
  resetInput: () => void;
  handleGetPdv: () => void;
  handleConferido: (row_pdv: number) => void;

  disableButtonCapa: boolean;

  totalVenda: number;
  totalImposto: number;
  totalCancelado: number;

  handleConferirTodos: () => void;
}

interface ProviderConferenciaDeVendasProps {
  children: ReactNode;
}

export const ConferenciaDeVendasContext = createContext(
  {} as ConferenciaDeVendasContextData,
);

export function ConferenciaDeVendasContextProvider({
  children,
}: ProviderConferenciaDeVendasProps): JSX.Element {
  const { loja } = useContext(LojaContext);
  const [pdvs, setPdvs] = useState<SelectProps[]>([]);
  const [loaderSearch, setLoadersearch] = useState<boolean>(false);
  const [searchRows, setSearchRows] = useState<SearchProps[]>([]);
  const [totalVenda, setTotalVenda] = useState<number>(0);
  const [totalImposto, setTotalImposto] = useState<number>(0);
  const [totalCancelado, setTotalCancelado] = useState<number>(0);
  const [handleClickPesquisa, setHandleClickPesquisa] =
    useState<boolean>(false);

  const [disableButtonCapa, setDisableButtonCapa] = useState<boolean>(false);

  const MySwal = withReactContent(Swal);

  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    setValue('loja_conferencia', loja.cod_loja);
  }, []);

  const watchLoja = watch('loja_conferencia');
  const watchFlgForcarRecalculo = watch('flg_forcar_recalculo');

  const handleSearchConferencia = useCallback(
    async (flg_forcar_recalculo: boolean) => {
      const { dta_mov, pdv, loja_conferencia } = getValues();

      try {
        setLoadersearch(true);
        const pdvExist = pdv ? (pdv.value !== -1 ? pdv.value : null) : null;
        const search = await getSearch(
          dta_mov,
          pdvExist,
          loja_conferencia,
          flg_forcar_recalculo,
        );

        if (search.length > 0) {
          setDisableButtonCapa(true);

          let calcVenda = 0;
          let calcImposto = 0;
          let calcCancelado = 0;

          search.forEach((value: any) => {
            calcVenda += Number(value.val_total_venda);
            calcImposto += Number(value.val_total_imposto);
            calcCancelado += Number(value.val_total_cancelado);
          });

          setTotalVenda(calcVenda);
          setTotalImposto(calcImposto);
          setTotalCancelado(calcCancelado);
          setSearchRows(search);
        } else {
          setSearchRows([]);
          setDisableButtonCapa(false);
          toast.warning(
            'Nenhum registro encontrado, conforme critérios informados!',
          );
        }
      } finally {
        setLoadersearch(false);
      }
    },
    [getValues],
  );

  const handleConferido = useCallback(
    async (row_pdv: number) => {
      const { dta_mov, loja_conferencia } = getValues();
      const conferido = await putConferido(loja_conferencia, dta_mov, row_pdv);

      if (conferido) {
        toast.success('Conferido com sucesso');
        handleSearchConferencia(false);
      } else {
        toast.warning('Não foi possivel conferir essa venda');
      }
    },
    [getValues, handleSearchConferencia],
  );

  const handleSearch = handleSubmit(async (dataForm) => {
    setHandleClickPesquisa(true);
    if (dataForm.flg_forcar_recalculo) {
      const continua = await MySwal.fire({
        html: '<h4>Forçar o Recálculo desmarcará os registros que talvez já tenham sido conferidos.</h4> <br/> Deseja realmente continuar?',
        showCancelButton: true,
        confirmButtonColor: '#0065FF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'SIM',
        cancelButtonText: 'NÃO',
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });

      if (continua) {
        handleSearchConferencia(dataForm.flg_forcar_recalculo);
      }
      setLoadersearch(false);
      return;
    }

    handleSearchConferencia(dataForm.flg_forcar_recalculo);
  });

  const resetInput = useCallback(() => {
    reset();
    setValue('flg_forcar_recalculo', false);
    setValue('loja_conferencia', loja.cod_loja);
  }, [reset, setValue, loja.cod_loja]);

  const handleGetPdv = useCallback(async () => {
    const { loja_conferencia } = getValues();
    const pdv = await getPdvs(loja_conferencia);
    const optionsPdv: SelectProps[] = pdv.map((value) => {
      return { value: value.num_pdv, label: String(value.num_pdv) };
    });
    optionsPdv.unshift({ value: -1, label: 'Todos' });
    setPdvs(optionsPdv);
  }, [getValues]);

  const handleCancel = useCallback(() => {
    setDisableButtonCapa(false);
    setSearchRows([]);
    setTotalVenda(0);
    setTotalImposto(0);
    setTotalCancelado(0);
  }, []);

  const handleConferirTodos = useCallback(async () => {
    const { dta_mov, loja_conferencia } = getValues();

    const rowsPdvsArray: number[] = [];

    searchRows.forEach((row) => {
      if (row.tipo_status === 1 && !row.flg_conferido) {
        rowsPdvsArray.push(row.num_pdv);
      }
    });

    const conferido = await putConferidoAll(
      loja_conferencia,
      dta_mov,
      rowsPdvsArray,
    );

    if (conferido) {
      toast.success('Conferido com sucesso');
      handleSearchConferencia(false);
    } else {
      toast.warning('Não foi possivel conferir essa venda');
    }
  }, [searchRows, getValues, handleSearchConferencia]);

  const nextDateConferencia = useCallback(async () => {
    if (searchRows.length > 0) {
      if (!searchRows.some((row) => !row.flg_conferido)) {
        const nextDate = await MySwal.fire({
          html: '<h4>Deseja avançar para a conferência do próximo dia?</h4>',
          showCancelButton: true,
          confirmButtonColor: '#0065FF',
          cancelButtonColor: '#DE350B',
          confirmButtonText: 'SIM',
          cancelButtonText: 'NÃO',
        }).then((result) => {
          if (result.isConfirmed) {
            return true;
          }
          return false;
        });

        if (nextDate) {
          const { dta_mov, flg_forcar_recalculo } = getValues();
          const myDate = parseISO(dta_mov);
          myDate.setDate(myDate.getDate() + 1);
          setValue('dta_mov', moment(myDate).format('YYYY-MM-DD'));
          handleSearchConferencia(flg_forcar_recalculo);
        }
        setLoadersearch(false);
      }
    }
  }, [getValues, MySwal, handleSearchConferencia, searchRows, setValue]);

  useEffect(() => {
    if (!handleClickPesquisa) {
      nextDateConferencia();
    } else {
      setHandleClickPesquisa(false);
    }
  }, [searchRows]);

  return (
    <ConferenciaDeVendasContext.Provider
      value={{
        setValue,
        register,
        errors,
        getValues,
        reset,
        control,
        handleSearch,
        watchLoja,
        loaderSearch,
        watchFlgForcarRecalculo,

        pdvs,
        handleCancel,
        resetInput,
        searchRows,
        handleGetPdv,
        handleConferido,
        disableButtonCapa,
        totalVenda,
        totalImposto,
        totalCancelado,
        handleConferirTodos,
      }}
    >
      {children}
    </ConferenciaDeVendasContext.Provider>
  );
}

export const useConferenciaDeVendas = (): ConferenciaDeVendasContextData => {
  return useContext(ConferenciaDeVendasContext);
};
